import React from 'react'
import { useState, useEffect } from 'react'

import styled, { css } from 'styled-components'
import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import SEO from '../seo'
import { BreadcrumbAuto, MainContainer } from '../Blocks'

import { MainTitle } from '../Typography'
import theme from '../../theme'

//TODO

const Body = styled.div`
	display: flex;
	width: 100%;
	margin-top: 20px;
`
const title = css`
	color: ${theme.colors.gray66};
	position: relative;
`

const AboutTitle = styled.h1`
	${title}
	font-size: 16px;
	margin-bottom: 50px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: ${theme.colors.black_fonts};
`

const About = styled.div`
	background-color: ${theme.colors.white};
`

const AboutText = styled.div`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: ${theme.colors.black_fonts};
	margin: 0px;
`

const InvasionComponent = ({ Layout, cosmicjsInformacoesEstaticas, location }) => {
	const [language, setLanguage] = useState('br')

	useEffect(() => {
		setLanguage(location.search.replace('?language=', ''))
	}, [location])

	return (
		<Layout>
			<SEO
				title={cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo}
				description={cosmicjsInformacoesEstaticas.metadata.banner_destaque.descricao}
			/>

			<MainContainer>
				<MediaQuery minDeviceWidth={1224}>
					{language === 'br' ? (
						<BreadcrumbAuto title={cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo} />
					) : (
						<BreadcrumbAuto title={cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo_ingles} />
					)}
				</MediaQuery>
				{language === 'br' ? (
					<MainTitle>{cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo}</MainTitle>
				) : (
					<MainTitle>{cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo_ingles}</MainTitle>
				)}
				<Body>
					<About>
						<AboutTitle
							dangerouslySetInnerHTML={{
								__html:
									language === 'br'
										? cosmicjsInformacoesEstaticas.metadata.banner_destaque.descricao
										: cosmicjsInformacoesEstaticas.metadata.banner_destaque.descricao_ingles
							}}
						/>
						<AboutText
							dangerouslySetInnerHTML={{
								__html:
									language === 'br'
										? cosmicjsInformacoesEstaticas.metadata.conteudo
										: cosmicjsInformacoesEstaticas.metadata.conteudo_ingles
							}}
						/>
					</About>
				</Body>
			</MainContainer>
		</Layout>
	)
}
export default InvasionComponent
