import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import InvasionComponent from '@monorepo-amais/commons/components/invasion'

const Comunicado = ({ data, location }) => (
	<InvasionComponent Layout={Layout} {...data} location={location}></InvasionComponent>
)
export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(title: { eq: "Comunicado de Privacidade" }) {
			id
			metadata {
				banner_destaque {
					titulo
					titulo_ingles
					descricao
					descricao_ingles
				}
				conteudo
				conteudo_ingles
			}
		}
	}
`

export default Comunicado
